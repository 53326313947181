var root;
var loginModal;
$(function () {
    root = $('body').data('root');

    // client side error handling
    var errorTreshold = 0;
    var maxErrorTreshold = 10;

    window.onerror = function (errorMsg, url, lineNumber, columnNumber, errorObject) {
        if (errorTreshold++ > maxErrorTreshold) {
            return;
        }

        var arg = {
            message: errorMsg,
            url: url,
            line: lineNumber,
            column: columnNumber
        };
        if (errorObject) {
            arg.stack = errorObject.stack;
        }

        var xhr = new XMLHttpRequest();
        xhr.open('POST', root + '/Error/LogJavaScriptError');

        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(arg));
    };

    // run js if Chrome is being used - fix for the parallel banners for chrome
    if ('WebkitAppearance' in document.documentElement.style) {
        // fix webkit bug with background-attachment:fixed and transform applied, only first banner will be fixed.
        $(".w-slider-mask .banner:not(:first)").css('background-attachment', 'scroll');
    }


    //Overriding default options
    Lobibox.notify.DEFAULTS = $.extend({}, Lobibox.notify.DEFAULTS, {
        size: pf_MiniNotifySize,
        position: pf_MiniNotifyPosition,
        rounded: pf_MiniNotifyRoundedBorders,
        delay: pf_MiniNotifyCloseDelay,
        delayIndicator: false,
        sound: false,
        title: false
    });

    InitPage();  
});

$(window).load(function () {
    $('.productname').matchHeight();
    $('.elementwrapper').matchHeight();

    // initialise page
    // lazy load any images
    $("img.lazy").each(function () {
        $(this).attr("src", $(this).attr("data-original")).fadeIn('slow');
        $(this).removeAttr("data-original");
    });
    $('.mbss-listItem2-wrapper').lightGallery({
        selector: '.videoPopBtn',
        loadYoutubeThumbnail: false,
        loadVimeoThumbnail: false
    });
    $('[data-remodal-id=modal]').remodal();
    $.scrollUp({
        scrollImg: true, // Set true to use image
    });
    //add data attributes to banner text
    $('.big-title-banner').data("ix", "fade-in-big-title-on-load");
    $('.sub-text-title').data("ix", "fade-in-sub-text-title-on-load");

    // site search handler - brochure
    $('#site-search-tbx').keypress(function (e) {
        if (e.which == 13) {
            $('#site-search-btn').click();
        }
    });
    $('#site-search-btn').click(function () {
        if ($('#site-search-tbx').val() != "") {
            var str = $('#site-search-tbx').val();
            window.location.href = root + "Home/SiteSearch?s=" + str;
        }
        return false;
    });

    // product search handler
    $("#prod-search-tbx").autocomplete({
        source: root + 'Products/AutoComplete',
        minLength: 2,
        select: function (event, ui) {
            window.location.href = ui.item.url;
        }
    });
    $('#prod-search-tbx').keypress(function (e) {
        if (e.which == 13) {
            $('#prod-search-btn').click();
        }
    });
    $('#prod-search-btn').click(function () {
        if ($('#prod-search-tbx').val() != "") {
            var str = $('#prod-search-tbx').val();
            window.location.href = root + "Products/Search?search=" + encodeURIComponent(str);
        }
        return false;
    });

    // bag handler
    $('#bag-opener').click(function () {
        SlideTopCart();
        return false;
    });

    $('#bag-closer').click(function () {
        SlideTopCart();
    });

    // login modal trigger
    loginModal = $('#login-modal').remodal();
    $('.login-modal-btn').click(function () {
        loginModal.open();
    });
    // login from modal form
    $('#login-form-modal').submit(function () {
        $.ajax({
            url: $(this).attr('action'),
            type: $(this).attr('method'),
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                if (data.result == "ok") {
                    if (data.redirecturl != "") {
                        window.location.href = data.redirecturl;
                    }
                    else {
                        window.location.reload();
                    }
                }
                else if (data.result == "fail") {
                    $('#modal-login-warning').fadeIn();
                }
                else {
                    alert("Error: " + data.error);
                }
            },
            error: function (xhr, err) {
                alert('Error: ' + err);
            }
        });
        return false;
    });

    var $cartChangesModal = $('#cart-changes-modal');
    if ($cartChangesModal.length > 0)
        $cartChangesModal.remodal().open();
});


// functions
function InitPage() {
    $('#top-cart-holder').hide();
    SetCartQty();
    LoadTopCart();
    CheckItems();
    //GetTopCart();
}

function CheckItems() {
    $.get(root + 'Checkout/CheckItemsCount', function (response) {
        var $proceedCheckoutButton = $('.pf-proceed-checkout-btn');
        if (response.hasItems)
            $proceedCheckoutButton.show();
        else
            $proceedCheckoutButton.hide();
    });
}

function GetTopCart() {
    LoadTopCart();
}

function SlideTopCart() {
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
    else {
        $('#top-cart-holder').slideUp();
    }
}

function GetAndOpenTopCart() {
    GetTopCart();
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
}

function SetCartQty() {
    $.post(root + "Cart/GetNumCartItems", function (data) {
        if (data.result == "ok") {
            if (data.qty == 1) {
                $('.cart-total-qty').html(data.qty + " Item");
            }
            else {
                $('.cart-total-qty').html(data.qty + " Items");
            }
        }
    });
}

function RegisterRsp(data) {
    if (data.result == "ok") {
        $('[data-remodal-id=modal]').remodal().open();
        $('#signup-tbx').val('');
    }
    else {
        $(".errorMessage").html(data.result);
        $("#emailError").remodal().open();

    }

}
function emailNotCorrect(data) {
    $('#emailNotCorrect').remodal().open();
}

// highlight html elements on change (.e.g cart totals)
jQuery.fn.highlight = function () {
    $(this).each(function () {
        var el = $(this);
        el.before("<div/>")
        el.prev()
            .width(el.width())
            .height(el.height())
            .css({
                "position": "absolute",
                "background-color": "#ffff99",
                "opacity": ".9"
            })
            .fadeOut(1000);
    });
}

// call from anywhere to load in the top cart
function LoadTopCart(callback) {
    $('.pf-top-cart-loader').load(root + 'cart/GetCart', function () {
        if (callback) {
            callback();
        }
    });
}

function OpenTopCart() {
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
}

// Platform JS events
// product events
$(document).on('itemAdded.product.platform', function (event, data) {
    console.log("EVENT_AddToCart");
    // switch based on global var
    switch (pf_AddCartNotifyAction) {
        case ("toastr"): {
            switch (data.result) {
                case ("ok"): {
                    //toastr["success"]("'" + data.productTitle + "' added to your " + pf_CartLabel);
                    Lobibox.notify(
                        'success',  // Available types 'warning', 'info', 'success', 'error'
                        {
                            msg: "'" + data.productTitle + "' has been added to your " + pf_CartLabel,
                            icon: 'fas fa-check-circle',
                            title: "Added to " + pf_CartLabel
                        }
                    );
                    LoadTopCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("ok-stock-limited"): {
                    //toastr["success"]("Sorry there are limited stock '" + data.productTitle + "'. We have added " + data.qty + " to your " + pf_CartLabel);
                    alert("Sorry there are limited stock '" + data.productTitle + "'. We have added " + data.qty + " to your " + pf_CartLabel);
                    LoadTopCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("ok-out-of-stock"): {
                    //toastr["warning"]("Sorry this item " + "'" + data.productTitle + "' is no longer in stock.");
                    alert("Sorry this item " + "'" + data.productTitle + "' is no longer in stock.");
                    LoadTopCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("fail"): {
                    //toastr["error"]("Sorry an error has occured. " + data.err);
                    alert("Sorry an error has occurred. " + data.err);
                    break;
                }
            }
            break;
        }
        case ("slidetop"): {
            switch (data.result) {
                case ("ok"): {
                    LoadTopCart();
                    OpenTopCart();
                    SetCartQty();
                    CheckItems();
                    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
                    break;
                }
                case ("ok-stock-limited"): {
                    alert("Sorry there are only " + data.qty + " of these items available in stock.");
                    LoadTopCart();
                    OpenTopCart();
                    SetCartQty();
                    CheckItems();
                    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
                    break;
                }
                case ("ok-out-of-stock"): {
                    alert("Sorry this item is no longer in stock.");
                    LoadTopCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("fail"): {
                    alert("Sorry an error has occured. " + data.err);
                    break;
                }
            }
            break;
        }
    }
});


// cart events
$(document).on('itemRemoved.cart.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"): {
            Lobibox.notify(
                'warning',  // Available types 'warning', 'info', 'success', 'error'
                {
                    msg: "'" + data.productTitle + "' has been removed from your " + pf_CartLabel,
                    icon: 'fas fa-info-circle',
                    title: "Removed from " + pf_CartLabel
                }
            );

            LoadTopCart();
            SetCartQty();
            CheckItems();

            break;
        }
        default: {
            LoadTopCart();
            SetCartQty();
            CheckItems();
            break;
        }
    }
});


$(document).on('itemUpdated.cart.platform', function (event, data) {
    switch (data.result) {
        case ("ok"): {
            SetCartQty();
            LoadTopCart(function () {
                $(".pf-top-cart-loader .checkout-cart-footer-row").highlight();  
            });
            break;
        }
        case ("ok-stock-limited"): {
            alert("Sorry there are limited stock '" + data.productTitle + "'. We have added " + data.qty + " to your " + pf_CartLabel);
            LoadTopCart(function () {
                $(".pf-top-cart-loader .checkout-cart-footer-row").highlight();
            });
            SetCartQty();
            break;
        }
        case ("ok-out-of-stock"): {
            alert("Sorry this item " + "'" + data.productTitle + "' is no longer in stock.");
            LoadTopCart(function () {
                $(".pf-top-cart-loader .checkout-cart-footer-row").highlight();
            });
            SetCartQty();
            break;
        }
        case ("fail"): {
            alert("Sorry an error has occurred. " + data.err);
            break;
        }
    }
});



// Favourites events
$(document).on('addedToFavs.product.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"):
            {
                switch (data.result) {
                    case ("ok"): {
                        Lobibox.notify(
                            'info',  // Available types 'warning', 'info', 'success', 'error'
                            {
                                msg: "'" + data.productTitle + "' has been added to your Favourites.",
                                icon: 'fas fa-star',
                                title: "Added to Favourites"
                            }
                        );
                        break;
                    }
                }

                break;
            }

    }
});

$(document).on('removedFromFavs.product.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"):
            {
                switch (data.result) {
                    case ("ok"): {
                        Lobibox.notify(
                            'warning',  // Available types 'warning', 'info', 'success', 'error'
                            {
                                msg: "'" + data.productTitle + "' has been removed from your Favourites.",
                                icon: 'far fa-star',
                                title: "Removed from Favourites"
                            }
                        );
                        break;
                    }
                }

                break;
            }

    }
});
